import React from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';

import CreditCurriculum from '../../components/CreditCurriculum';
import CreditVocab from '../../components/CreditVocab';
import ExternalQuiz from '../../components/ExternalQuiz';
import FlodeskModal from '../../components/Flodesk/SignupModal';
import OpinionDisclaimer from '../../components/OpinionDisclaimer';
import '../../scss/sequinUniversity.scss';

const SequinUniversity = () => {
  // const pageData = useStaticQuery(graphql`
  //   query uniQuery {
  //     allContentfulSequinUniversityPage {
  //       nodes {
  //         headerTitle
  //         headerSubtitle
  //         headerBody {
  //           childMarkdownRemark {
  //             html
  //           }
  //         }
  //         headerImage {
  //           url
  //           title
  //         }
  //         sequinInfoTitle
  //         sequinInfoBody {
  //           childMarkdownRemark {
  //             html
  //           }
  //         }
  //         sequinInfoFooter
  //         sequinInfoImage {
  //           url
  //           title
  //         }
  //       }
  //     }

  //     allContentfulExternalQuiz {
  //       nodes {
  //         slug
  //         sectionHeader
  //         sectionSubheader
  //         questionHeader
  //         questionSubheader
  //         quizResponse {
  //           answer
  //           url
  //           trackingEventId
  //         }
  //       }
  //     }
  //   }
  // `);

  // const [modalShow, setModalShow] = React.useState(false);

  // let page = pageData.allContentfulSequinUniversityPage.nodes[0];
  // let headerBody = page.headerBody.childMarkdownRemark.html;
  // let quizData = pageData.allContentfulExternalQuiz.nodes.filter(
  //   quiz => quiz.slug === '/sequin-university'
  // )[0];

  // return (
  //   <div className="sequin-university">
  //     <div className="uni__head">
  //       <div className="site-wrap">
  //         <div className="hero-container d-flex justify-content-md-between">
  //           <div className="hero__left">
  //             <h2>{page.headerTitle}</h2>
  //             <h3>{page.headerSubtitle}</h3>
  //             {headerBody && (
  //               <p dangerouslySetInnerHTML={{ __html: headerBody }}></p>
  //             )}
  //             <Link
  //               className="purple-button"
  //               to="/sequin-university"
  //               onClick={() => setModalShow(true)}
  //             >
  //               Sign up with Sequin today
  //             </Link>
  //           </div>
  //           <div className="hero__right">
  //             <img
  //               src={page.headerImage.url}
  //               className="uni-hero-img"
  //               alt={page.headerImage.title}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //     <div className="uni__body">
  //       <CreditVocab />
  //       <CreditCurriculum />
  //       <ExternalQuiz data={quizData} />
  //     </div>
  //     <OpinionDisclaimer />
  //     <FlodeskModal show={modalShow} onHide={() => setModalShow(false)} />
  //   </div>
  // );

  React.useEffect(() => {
    navigate('/');
  }, []);

  return <></>;
};

export default SequinUniversity;
